import { useRef } from "react";
import { CheckIcon } from "@heroicons/react/solid";

export default function AnalyzeForm({
  onTotalsResponse,
  onJReadabilityResponse,
}: {
  onTotalsResponse: (response?: {
    totalCharacterCount?: Number;
    totalMorphemeCount?: Number;
    totalMoraCount?: Number;
  }) => void;
  onJReadabilityResponse: (resposne?: { readabilityScore?: Number }) => void;
}) {
  const textElement = useRef<HTMLTextAreaElement>(null);

  const analyzeText = () => {
    onTotalsResponse();
    onJReadabilityResponse();

    const text = textElement.current?.value;

    const totalsResponse = fetch(
      "https://kaiseki-service.herokuapp.com/totals",
      {
        method: "POST",
        body: JSON.stringify({ text }),
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );

    const jreadabilityResponse = fetch(
      "https://kaiseki-service.herokuapp.com/jreadability-score",
      {
        method: "POST",
        body: JSON.stringify({ text }),
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );

    totalsResponse.then((response) => response.json()).then(onTotalsResponse);
    jreadabilityResponse
      .then((response) => response.json())
      .then(onJReadabilityResponse);
  };

  return (
    <div className="h-full">
      <textarea
        name="text"
        className="mt-1 relative rounded-md shadow-sm h-3/4 border-4 border-dashed border-gray-200 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2 sm:text-sm border-gray-300 rounded-md"
        placeholder="Text to analyze"
        ref={textElement}
      />
      <div className="my-4 h-1/4">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={analyzeText}
        >
          <CheckIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Analayze
        </button>
      </div>
    </div>
  );
}
