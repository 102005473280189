export default function Results({
  words,
  characters,
  morea,
  readability,
}: {
  words?: Number | "-";
  characters?: Number | "-";
  morea?: Number | "-";
  readability?: Number | "-";
}) {
  return (
    <section className="text-gray-400 bg-gray-900 body-font">
      <div className="container px-2 py-4 mx-auto">
        <div className="flex flex-wrap -m-4 text-center">
          <div className="p-4 sm:w-1/4 w-1/2">
            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
              {characters?.toLocaleString()}
            </h2>
            <p className="leading-relaxed">Characters</p>
          </div>
          <div className="p-4 sm:w-1/4 w-1/2">
            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
              {words?.toLocaleString()}
            </h2>
            <p className="leading-relaxed">Words</p>
          </div>
          <div className="p-4 sm:w-1/4 w-1/2">
            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
              {morea?.toLocaleString()}
            </h2>
            <p className="leading-relaxed">Morae</p>
          </div>
          <div className="p-4 sm:w-1/4 w-1/2">
            <h2 className="title-font font-medium sm:text-4xl text-3xl text-white">
              {readability}
            </h2>
            <p className="leading-relaxed">Readability</p>
          </div>
        </div>
      </div>
    </section>
  );
}
